import React from 'react'


function Nav() {

  return (
    <nav className='navbar'>
      <div className='title'>
        eazzze.net
      </div>
    </nav>
  )
}


export default Nav